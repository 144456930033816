<template>
  <tbody v-if="timeCardActivity" :id="timeCardActivity.id" :data-uuid="uuid">
                <tr>
                  <td class="align-middle">
                    <v-select class="activity-select row-input" :clearable="false" :searchable="true" v-model="timeCardActivity.activity_id" :options="allActivities" :value="timeCardActivity.activity.id" placeholder="Select Activity" label="title" index="id" @input="updateActivity"/>
                  </td>
                  <td class="align-middle">
                    <button :disabled="!timeCardActivity.activity.coach_impediment" class="btn btn-primary" @click="openTraineeModal">Trainees</button>
                  </td>
                  <td class="align-middle">
                    <button :disabled="!timeCardActivity.activity.topic_impediment" class="btn btn-primary" @click="openTopicModal">Audiences</button>
                  </td>
                  <td class="align-middle">
                    <v-select class="time-amount-selects row-input" id="regions" ref="regions" :clearable="false" :searchable="false" :options="currentUser.regions" v-model="timeCardActivity.region_id" :value="timeAmounts.region_id" label="name" index="id" @input="update" />
                  </td>
                  <td class="align-middle">
                    <v-select class="time-amount-selects" :clearable="false" :searchable="false" :options="timeAmounts" v-model="timeCardActivity.day_one" :value="timeAmounts.name" label="name" index="name" @input="update"/>
                  </td>
                  <td class="align-middle">
                    <v-select class="time-amount-selects" :clearable="false" :searchable="false" :options="timeAmounts" v-model="timeCardActivity.day_two" :value="timeAmounts.name" label="name" index="name" @input="update"/>
                  </td>
                  <td class="align-middle">
                    <v-select class="time-amount-selects" :clearable="false" :searchable="false" :options="timeAmounts" v-model="timeCardActivity.day_three" :value="timeAmounts.name" label="name" index="name" @input="update"/>
                  </td>
                  <td class="align-middle">
                    <v-select class="time-amount-selects" :clearable="false" :searchable="false" :options="timeAmounts" v-model="timeCardActivity.day_four" :value="timeAmounts.name" label="name" index="name" @input="update"/>
                  </td>
                  <td class="align-middle">
                    <v-select class="time-amount-selects" :clearable="false" :searchable="false" :options="timeAmounts" v-model="timeCardActivity.day_five" :value="timeAmounts.name" label="name" index="name" @input="update"/>
                  </td>
                  <td class="align-middle">
                    <v-select class="time-amount-selects" :clearable="false" :searchable="false" :options="timeAmounts" v-model="timeCardActivity.day_six" :value="timeAmounts.name" label="name" index="name" @input="update"/>
                  </td>
                  <td class="align-middle">
                    <v-select class="time-amount-selects" :clearable="false" :searchable="false" :options="timeAmounts" v-model="timeCardActivity.day_seven" :value="timeAmounts.name" label="name" index="name" @input="update"/>
                  </td>
                  <td class="align-middle">
                    <b class="week-activity-total">{{ totalDays }}</b>
                  </td>
                  <td class="align-middle" v-if="editMode === false">
                    <button type="button" @click="openCommentModal" class="btn btn-primary">Comment</button>
                  </td>
                  <td class="align-middle" v-if="editMode === false">
                    <button @click="deleteActivity" type="button" class="btn btn-danger btn-circle"><font-awesome-icon icon="times"/></button>
                  </td>
                </tr>
                <tr v-if="viewTraineesTopics">
                  <!-- Trainees -->
                  <td colspan="12" v-if="timeCardActivity.trainees && timeCardActivity.trainees.length > 0">
                    <b class="float-left">Trainees: </b>
                    <p class="float-left ml-1 pb-0 mb-0" v-for="(trainee, index) in timeCardActivity.trainees" :key="trainee.id"> {{ trainee.first_name }} {{ trainee.last_name }}{{ index !== timeCardActivity.trainees.length - 1 ? ", " : "" }}</p>
                  </td>
                  <td colspan="12" v-else>
                    <b class="float-left">No Trainees Currently.</b>
                  </td>
                </tr>
                <tr v-if="viewTraineesTopics">
                  <!-- Trainees -->
                  <td colspan="12" v-if="timeCardActivity.topics && timeCardActivity.topics.length > 0">
                    <b class="float-left">Audiences: </b>
                    <p class="float-left ml-1 pb-0 mb-0" v-for="(topic, index) in timeCardActivity.topics" :key="index"> {{ topic.title }}{{ index !== topic.length - 1 ? ", " : "" }}</p>
                  </td>
                  <td colspan="12" v-else>
                    <b class="float-left">No Topics Currently.</b>
                  </td>
                </tr>
  </tbody>
  <loading-overlay v-else/>
</template>

<script>
import { TimeCardActivityService } from '@/common/services/time_card_activity.service';
import { FilterService } from '@/common/services/filter.service';
import HelperMethods from '@/common/mixins/helper_methods';
import ImpedimentHelper from '@/common/mixins/impediment_helper';
import LoadingOverlay from '@/common/components/loading-overlay.vue';
import uuidv4 from 'uuid/v4';

export default {
  name: 'TimeCardRow',
  mixins: [HelperMethods, ImpedimentHelper],
  props: {
    timeCardActivity: Object,
    allActivities: Array,
    timeAmounts: Array,
    viewTraineesTopics: Boolean,
    currentUser: Object,
    editMode: Boolean,
  },
  components: {
    LoadingOverlay,
  },
  data() {
    return {
      filters: FilterService.defaultData(),
      currentPage: 1,
      totalRows: 0,
      perPage: 100,
      totalDays: 0,
      uuid: uuidv4(),
    };
  },
  created() {
    this.$eventHub.$on(this.uuid, this.checkForImpediments);
    this.log('timeCardRow -> created', {
      timeCardActivity: this.timeCardActivity,
      allActivities: this.allActivities,
      timeAmounts: this.timeAmounts,
      viewTraineesTopics: this.viewTraineesTopics,
      currentUser: this.currentUser,
    }, false);
    if (this.currentUser.regions) {
      if (this.currentUser.regions && this.currentUser.regions.length === 1) {
        this.timeCardActivity.region_id = this.currentUser.regions[0].id;
      } else if (this.timeCardActivity.region_id === null) {
        this.timeCardActivity.region_id = this.currentUser.regions[0].id;
      }
    }
    this.totalDays = this.calculateWeekTotal(this.timeCardActivity);
  },
  mounted() {
    this.updateActivity();
    this.updateRegionHeight();
    this.registerRow(this.uuid);
  },
  beforeDestroy() {
    this.$eventHub.$off(this.uuid);
    this.deregisterRow(this.uuid);
  },
  methods: {
    openTraineeModal() {
      this.log('timeCardRow -> openTraineeModal');
      this.openModal('trainee');
    },
    openTopicModal() {
      this.log('timeCardRow -> openTopicModal');
      this.openModal('topic');
    },
    openCommentModal() {
      this.log('timeCardRow -> openCommentModal');
      this.openModal('comment');
    },
    openModal(type) {
      const params = {
        whichModal: type,
        timeCardActivity: this.timeCardActivity,
        rowUuid: this.uuid,
      };
      this.log('timeCardRow -> openModal', params);
      this.$emit('openModal', params);
    },
    deleteActivity() {
      this.log('timeCardRow -> deleteActivity');
      this.$emit('deleteActivity', this.timeCardActivity);
      this.removeAllImpedimentsFromRow(this.uuid);
    },
    forceRefresh() {
      this.log('timeCardRow -> forceRefresh');
      this.updateRegionHeight();
      this.$emit('refresh');
    },
    updateActivity() {
      this.update();
    },
    updateRegionHeight() {
      if (this.$refs.regions.$el.clientHeight < 50) {
        this.$refs.regions.$el.style.marginBottom = '0px';
      } else {
        this.$refs.regions.$el.style.marginBottom = '13px';
      }
    },
    update() {
      this.log('timeCardRow -> Update', this.timeCardActivity);
      this.checkForImpediments();
      TimeCardActivityService.update(this.timeCardActivity.id, { ...this.timeCardActivity, ...{ created: true } })
        .then((response) => {
          this.log('timeCardRow -> update -> update', response);
          this.totalDays = this.calculateWeekTotal(this.timeCardActivity);
          this.forceRefresh();
        });
    },
  },
  watch: {
    timeCardActivity() {
      this.checkForImpediments();
    },
  },
};
</script>

<style lang="scss" scoped>
  .btn-circle {
    width: 30px;
    height: 30px;
    padding: 4px 1px 4px 1px;
    border-radius: 15px;
    text-align: center;
    font-size: 16px;
    line-height: 0.42857;
  }

  .missing {
    background: rgba(255, 133, 124, 0.4);
  }

  .activity-select {
    min-width: 200px;
  }

  .time-amount-selects {
    max-width: 160px;
    min-width: 160px;
    max-height: 50px;
  }

  .time-card-table{
    min-height: 500px;
  }

  button:disabled,
  button[disabled]{
    background-color: grey !important;
    border-color: grey !important;
  }

  .v-select {
    .dropdown-menu {
      top: 33px;
      min-width: 90px !important;
      width: auto;
      background: #ffffff;
      li {
        min-width: 0;
      }
    }
    .dropdown-toggle {
      .vs__selected-options {
        input[type="search"] {
          opacity: 1 !important;
        }
        .single.searching:not(.open):not(.loading) input[type=search] {
          opacity: 1 !important;
        }
      }
    }
  }
  .checkbox-container {
    /*border-radius: 10px;*/
    /*background-color: #bfbfbf;*/
  }

  .flash-warn {
    opacity: 1;
    animation: fade 2s linear;
  }

  @keyframes fade {
    0%,100% { background-color: rgba(255, 255, 255, 1) }
    50% { background-color: rgba(125, 0, 0, 0.1) }
  }

</style>
