<template>
  <div v-if="timeCardActivity">
    <b-modal
      centered
      v-model="modalVisibility"
      size="xl"
      title="Comment"
      header-bg-variant="info"
      header-text-variant="white"
      footer-bg-variant="light"
      ok-only
      ok-variant="info"
      ok-title="Close"
      >
      <textarea v-model="timeCardActivity.comment" class="comment-field" placeholder="Leave your comment here" @input="update"></textarea>
    </b-modal>
  </div>
</template>

<script>
import { TimeCardActivityService } from '@/common/services/time_card_activity.service';
import _ from 'lodash';

export default {
  name: 'comment-modal',
  props: {
    timeCardActivity: Object,
    visibility: Boolean,
    isEditing: Boolean,
  },
  data() {
    return {
      modalVisibility: false,
    };
  },
  watch: {
    visibility(val) {
      this.modalVisibility = val;
    },
    modalVisibility() {
      if (this.modalVisibility === false) {
        this.$emit('onChange', this.modalVisibility);
      }
    },
  },
  methods: {
    update: _.debounce(function updateRecord() {
      TimeCardActivityService.update(this.timeCardActivity.id, this.timeCardActivity);
    }),
  },
};
</script>

<style scoped>
  .comment-field {
    width: 100%;
    min-height: 150px;
  }
</style>
