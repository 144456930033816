<template>
  <div v-if="timeCard && timeCardActivities && user">

    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom ">
      <div class="float-left">
        <h4 class="float-left mr-3" id="top">Editing Activity Report Ending <small>{{ formattedDate(timeCard.week_start_date, 7) }}</small></h4>
        <h5 class="float-left"><b-badge :variant="determineStatus()">{{ timeCard.status }}</b-badge></h5>
      </div>
      <div class="float-right">
        <button class="btn btn-secondary" v-on:click="viewTraineesTopics = !viewTraineesTopics">
          <input class="m-0 p-0" type="checkbox" :value="timeCard" :id="timeCard.id" v-model="viewTraineesTopics">
          <label class="ml-2 mb-0 pb-0" for="timeCard.id">View Trainees & Audiences</label>
        </button>
        <button class="btn btn-success m-1" v-on:click="$refs.timeCardSubmission.show()">Submit</button>
        <button class="btn btn-success m-1" v-on:click="saveTimeCard('In Progress')">Save</button>
      </div>
    </div>

    <div v-if="timeCard.rejection_note && timeCard.status !== 'Approved'" class="alert alert-danger rejection-notes" role="alert" align="left">
      <h4>Rejection Note(s):</h4>
      <p>{{ timeCard.rejection_note }}</p>
    </div>

    <div class="card">
      <div class="rainbow-background"></div>
      <div class="table-responsive time-card-table">
        <table class="card-table table">
          <thead>
          <tr>
            <th class="align-middle">Activity</th>
            <th class="align-middle">Trainees</th>
            <th class="align-middle">Content/Audiences</th>
            <th class="align-middle w-20">Region<br/></th>
            <th class="align-middle w-20">{{ noDayFormattedDate(timeCard.week_start_date, 1) }}</th>
            <th class="align-middle w-20">{{ noDayFormattedDate(timeCard.week_start_date, 2) }}</th>
            <th class="align-middle w-20">{{ noDayFormattedDate(timeCard.week_start_date, 3) }}</th>
            <th class="align-middle w-20">{{ noDayFormattedDate(timeCard.week_start_date, 4) }}</th>
            <th class="align-middle w-20">{{ noDayFormattedDate(timeCard.week_start_date, 5) }}</th>
            <th class="align-middle w-20">{{ noDayFormattedDate(timeCard.week_start_date, 6) }}</th>
            <th class="align-middle w-20">{{ noDayFormattedDate(timeCard.week_start_date, 7) }}</th>
            <th class="align-middle w-20">Week</th>
            <th class="align-middle">Comment</th>
            <th/>
          </tr>
          </thead>
          <time-card-row v-for="(timeCardActivity, index) in timeCardActivities" :key="index" :currentUser="user" :all-activities="allActivities" :time-card-activity="timeCardActivity" :time-amounts="timeAmounts" :view-trainees-topics="viewTraineesTopics" :edit-mode="false" @openModal="openModal" @deleteActivity="deleteActivity" @refresh="refresh"/>
          <tr>
            <td><button class="btn btn-success" v-on:click="addNewActivity">New Activity</button></td>
            <td/>
            <td/>
            <td class="align-middle"><b>Total Days</b></td>
            <td class="align-middle"><b>{{ sumDay('day_one') }}</b></td>
            <td class="align-middle"><b>{{ sumDay('day_two') }}</b></td>
            <td class="align-middle"><b>{{ sumDay('day_three') }}</b></td>
            <td class="align-middle"><b>{{ sumDay('day_four') }}</b></td>
            <td class="align-middle"><b>{{ sumDay('day_five') }}</b></td>
            <td class="align-middle"><b>{{ sumDay('day_six') }}</b></td>
            <td class="align-middle"><b>{{ sumDay('day_seven') }}</b></td>
            <td class="align-middle"><b>{{ weekTotalDays }} Total Days</b></td>
            <td/>
          </tr>
        </table>
      </div>
      <div class="card-footer">
        <div class="btn-group float-right">
          <router-link v-if="adminMode" :to="{ name: 'TimeManagementReview', params: { id: timeCard.id} }" tag="button" class="btn btn-secondary">Back</router-link>
          <router-link v-else :to="{ name: 'TimeCardIndex' }" tag="button" class="btn btn-secondary">Back</router-link>
        </div>
      </div>
    </div>

    <br/>

    <!-- Trainee Modal -->
    <trainee-modal :timeCardActivity="currentTimeCardActivity" :visibility="traineeModalVisibility" :isEditing="true" @onChange="close"/>

    <!-- Topic Modal -->
    <topic-modal :timeCardActivity="currentTimeCardActivity" :visibility="topicModalVisibility" :all-topics="allTopics" :isEditing="true" @onChange="close"/>

    <!-- Comment Modal -->
    <comment-modal :timeCardActivity="currentTimeCardActivity" :visibility="commentModalVisibility" :isEditing="true" @onChange="close"/>

    <!-- Modal Component -->
    <b-modal ref="timeCardSubmission" title="Confirm Submission" header-bg-variant="info" header-text-variant="light" ok-variant="success" ok-title="Submit" @ok="saveTimeCard('Submitted')" centered>
      <p>Are you sure you want to submit your Report?</p>
    </b-modal>

  </div>

  <loading-overlay v-else />

</template>

<script>
import { TimeCardService } from '@/common/services/time_card.service';
import { TimeCardActivityService } from '@/common/services/time_card_activity.service';
import { TimeCardActivityUserService } from '@/common/services/time_card_activity_user.service';
import { TimeCardActivityTopicService } from '@/common/services/time_card_activity_topic.service';
import { TimeAmountService } from '@/common/services/time_amount.service';
import { ActivityService } from '@/common/services/activity.service';
import { FilterService } from '@/common/services/filter.service';
import { TopicService } from '@/common/services/topic.service';
import TimeCardRow from '@/common/components/time-card-row.vue';
import HelperMethods from '@/common/mixins/helper_methods';
import ImpedimentHelper from '@/common/mixins/impediment_helper';
import LoadingOverlay from '@/common/components/loading-overlay.vue';
import TraineeModal from '@/common/components/trainee-modal.vue';
import TopicModal from '@/common/components/topic-modal.vue';
import CommentModal from '@/common/components/comment-modal.vue';
import _ from 'lodash';
import Vue from 'vue';

export default {
  name: 'TimeCard',
  mixins: [HelperMethods, ImpedimentHelper],
  components: {
    LoadingOverlay,
    TraineeModal,
    TopicModal,
    CommentModal,
    TimeCardRow,
  },
  props: {
    timeCardId: Number,
    user: Object,
    adminMode: Boolean,
  },
  data() {
    return {
      selectedDayOne: null,
      timeCard: null,
      timeCardActivities: null,
      timeCardAmounts: null,
      filters: FilterService.defaultData(),
      weekTotalDays: 0.0,
      currentTimeCardActivity: null,
      traineeModalVisibility: false,
      topicModalVisibility: false,
      commentModalVisibility: false,
      timeAmounts: null,
      currentPage: 1,
      perPage: 100,
      totalRows: 0,
      totalRecords: 0,
      allActivities: null,
      updatedTimeCardActivities: [],
      unsavedActivities: [],
      activitiesToDelete: [],
      leavePage: false,
      firstLoaded: false,
      viewTraineesTopics: false,
      allTopics: [],
      impedimentBlock: false,
      // debug: false,
    };
  },
  created() {
    this.getTimeCard();
    this.getAllTopics();
    this.getTimeAmounts(this.currentPage, this.perPage);
    this.getActivities(this.currentPage, this.perPage);
    Vue.prototype.$impediments = [];
    Vue.prototype.$rowRegistry = [];
  },
  /**
   * This spooky thing deletes all associated activities with an ID of -1, aka non existant
   */
  beforeDestroy() {
    Vue.prototype.$impediments = [];
    Vue.prototype.$rowRegistry = [];
    if (this.timeCardActivities) {
      _.forEach(this.timeCardActivities, (val) => {
        if (val.activity_id === -1) {
          TimeCardActivityService.destroy(val.id);
        }
      });
    }
  },
  methods: {
    refresh() {
      this.log('refresh');
      this.close();
      this.getTimeCard();
    },
    getAllTopics() {
      TopicService.query({ page: 1, per_page: 10000 })
        .then((response) => {
          this.log('getAllTopics -> TopicService.query', response);
          this.allTopics = response.data;
        });
    },
    determineStatus() {
      this.log('determineStatus', null, false);
      switch (this.timeCard.status) {
        case 'Approved':
          return 'success';
        case 'In Progress':
          return 'primary';
        case 'Submitted':
          return 'warning';
        case 'Rejected':
          return 'danger';
        default:
          return 'primary';
      }
    },
    getTimeAmounts(page, perPage) {
      this.log('getTimeAmounts', { page, perPage });
      const params = FilterService.build({ page, per_page: perPage }, this.filters);
      TimeAmountService.query(params).then((response) => {
        this.currentPage = parseFloat(response.headers['x-page']);
        this.totalRows = parseFloat(response.headers['x-total']);
        this.timeAmounts = response.data.filter(timeAmount => timeAmount.name !== '');
      });
    },
    getTimeCard() {
      this.log('getTimeCard');
      TimeCardService.get(this.timeCardId)
        .then((response) => {
          this.timeCard = response.data;
          // Only allow the user to continue on the Edit page if the status is of an editable type, otherwise push them back
          if (this.adminMode === false && response.data.status !== 'In Progress' && response.data.status !== 'Rejected') {
            this.$router.go(-1);
            this.$notify({
              type: 'error',
              title: 'Permission Denied',
              text: 'You aren\'t allowed to edit this TimeCard',
              width: 350,
            });
          }

          this.filters.time_card_id_eq = this.timeCard.id;
          const params = FilterService.build({ page: 1, per_page: 50 }, this.filters);
          return TimeCardActivityService.query(params);
        })
        .then((response) => {
          this.timeCardActivities = response.data;
          this.viewTraineesTopics = true;
        })
        .then(() => {
          this.updateTotalDays(this.timeCardActivities);
        });
    },
    getActivities(page, perPage) {
      this.log('getActivities', { page, perPage });
      this.filters.s = 'created_at asc';
      this.filters.is_archived = false;
      const params = FilterService.build({ page, per_page: perPage }, this.filters);
      ActivityService.query(params).then((response) => {
        this.currentPage = parseFloat(response.headers['x-page']);
        this.totalRows = parseFloat(response.headers['x-total']);
        this.log('ActivityService', response);
        this.allActivities = response.data;
      });
    },
    sumDay(prop) {
      this.log('sumDay', prop, false);
      let sum = 0;

      const filteredTimeCards = this.timeCardActivities.filter(timeCardActivity => timeCardActivity[prop]);
      _.forEach(filteredTimeCards, (timeCardActivity) => {
        sum += this.convertTimeStringToValue(timeCardActivity[prop]);
      });

      return sum;
    },
    openModal(params) {
      // format example: { whichModal: '', timeCardActivity: 0, rowUuid: '' }
      this.log('openModal', params);
      this.close();
      this.currentTimeCardActivity = params.timeCardActivity;
      if (params.whichModal === 'trainee') {
        this.traineeModalVisibility = true;
      } else if (params.whichModal === 'topic') {
        this.topicModalVisibility = true;
      } else if (params.whichModal === 'comment') {
        this.commentModalVisibility = true;
      }
    },
    close() {
      this.log('close');
      this.currentTimeCardActivity = {};
      this.topicModalVisibility = false;
      this.traineeModalVisibility = false;
      this.commentModalVisibility = false;
    },
    deleteActivity(timeCardActivity) {
      this.log('deleteActivity', timeCardActivity);
      if (timeCardActivity) {
        this.close();
        TimeCardActivityService.destroy(timeCardActivity.id)
          .then((response) => {
            this.log('deleteActivity => destroy', response);
            this.refresh();
          });
      }
    },
    addNewActivity() {
      const [defaultActivity] = this.allActivities;
      const newActivity = {
        time_card_id: Number(this.timeCardId),
        activity: defaultActivity,
        activity_id: defaultActivity.id,
      };

      this.log('addNewActivity', { newActivity });
      TimeCardActivityService.create(newActivity)
        .then((response) => {
          this.log('addNewActivity -> create', response);
          this.timeCardActivities.push(response.data);
        });
    },
    saveTimeCard(status) {
      this.log('saveTimeCard', status);
      this.checkAllRows();
      let selectedAllActivities = true;
      let selectedAllActivitiesTimes = true;
      const impedimentsMet = this.areAllImpedimentsMet();
      _.forEach(this.timeCardActivities, (activity) => {
        const activityDiv = document.getElementById(activity.id);

        if (activity.activity.id === -1) {
          const inputs = activityDiv.getElementsByTagName('TD');
          inputs[0].childNodes[0].classList.add('missing');
          selectedAllActivities = false;
        }
        if (activity.day_one === '0.0' && activity.day_two === '0.0' && activity.day_three === '0.0' && activity.day_four === '0.0' && activity.day_five === '0.0' && activity.day_six === '0.0' && activity.day_seven === '0.0') {
          const inputs = activityDiv.getElementsByClassName('time-amount-selects');
          _.forEach(inputs, (val) => {
            val.classList.add('missing');
          });
          selectedAllActivitiesTimes = false;
          selectedAllActivities = false;
        }

        if (activity.activity.id !== -1 && (activity.day_one !== '0.0' || activity.day_two !== '0.0' || activity.day_three !== '0.0' || activity.day_four !== '0.0' || activity.day_five !== '0.0' || activity.day_six !== '0.0' || activity.day_seven !== '0.0')) {
          activityDiv.classList.remove('missing');
        }
      });

      if (selectedAllActivities && impedimentsMet) {
        if (this.timeCard.status === 'Rejected' && status === 'Submitted') {
          this.timeCard.status = 'Resubmitted';
        } else if (status !== 'In Progress') {
          this.timeCard.status = status;
        }

        TimeCardService.update(this.timeCardId, this.timeCard)
          .then(() => {
            this.leavePage = true;
            if (this.adminMode) {
              this.$router.push({ name: 'TimeManagementReview', params: { id: this.timeCardId } });
            } else {
              this.$router.push({ name: 'TimeCardShow', params: { id: this.timeCardId } });
            }
          });
      }

      if (selectedAllActivitiesTimes === false) {
        this.$notify({
          type: 'error',
          title: 'Days Worked Required',
          text: 'You must include your days worked',
          width: 350,
        });
      }
      if (selectedAllActivities === false) {
        this.$notify({
          type: 'error',
          title: 'Activity Required',
          text: 'You must include an activity',
          width: 350,
        });
      }
      if (impedimentsMet === false) {
        const impedimentActivities = [];
        const impediments = this.getImpediments();
        Object.keys(impediments).forEach((impediment) => {
          Object.keys(this.timeCardActivities).forEach((activity) => {
            if ((impediments[impediment].activity_uuid === this.timeCardActivities[activity].original_activity.uuid) && (!impedimentActivities.includes(this.timeCardActivities[activity].original_activity.title))) impedimentActivities.push(this.timeCardActivities[activity].original_activity.title);
          });
        });
        this.$notify({
          type: 'error',
          title: 'Missing Information',
          text: `‘Trainees’ and/or 'Audiences' are required for: <br/> <b>${impedimentActivities.join(', ')}</b>`,
          width: 350,
        });
      }
    },
    updateTotalDays(timeCardActivities) {
      this.log('updateTotalDays', timeCardActivities, false);
      if (this.firstLoaded === false) {
        this.firstLoaded = true;
      }
      let runningTotal = 0.0;

      for (let i = 0; i < timeCardActivities.length; i += 1) {
        runningTotal += this.calculateWeekTotal(timeCardActivities[i]);
      }

      this.weekTotalDays = runningTotal;
    },
    getTrainees(timeCardActivity) {
      this.log('getTrainees', timeCardActivity);
      this.filters.time_card_activity_id_eq = timeCardActivity.id;
      const params = FilterService.build({ page: 1, per_page: 1000 }, this.filters);
      return TimeCardActivityUserService.query(params)
        .then(response => response.data);
    },
    getTopics(timeCardActivity) {
      this.log('getTopics', timeCardActivity);
      this.filters.time_card_activity_id_eq = timeCardActivity.id;
      const params = FilterService.build({ page: 1, per_page: 1000 }, this.filters);
      return TimeCardActivityTopicService.query(params)
        .then(response => response.data);
    },
  },
};

</script>

<style lang="scss">
  .btn-circle {
    width: 30px;
    height: 30px;
    padding: 4px 1px 4px 1px;
    border-radius: 15px;
    text-align: center;
    font-size: 16px;
    line-height: 0.42857;
  }

  .missing {
    background: rgba(255, 133, 124, 0.4);
  }

  .activity-select {
    min-width: 200px;
  }

  .time-amount-selects {
    max-width: 160px;
    min-width: 160px;
    max-height: 50px;
  }

  .time-card-table{
    min-height: 500px;
  }

  .row-input{
    .form-control{
      height: 0px !important;
    }
  }
  .v-select {
    .dropdown-menu {
      top: 33px;
      min-width: 90px !important;
      width: auto;
      background: #ffffff;
      li {
        min-width: 0;
      }
    }
    .dropdown-toggle {
      .vs__selected-options {
        input[type="search"] {
          opacity: 1 !important;
        }
        .single.searching:not(.open):not(.loading) input[type=search] {
          opacity: 1 !important;
        }
      }
    }
  }
  .checkbox-container {
    /*border-radius: 10px;*/
    /*background-color: #bfbfbf;*/
  }
</style>
